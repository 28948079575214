<app-header></app-header>

<div class="callout">
  <h1>Create an Account</h1>
  <p>With an account, you can save your messages and log in from any device.</p>
</div>

<div class="callout">

  <form (ngSubmit)="register()" #registerForm="ngForm">
    <br />
    <div class="form-group">
      <label for="Email">Email</label>
      <input type="email" name="Email" id="Email" [(ngModel)]="model.Email" #Email="ngModel" class="form-control" placeholder="Email Address" aria-describedby="EmailHelp" required email>
      <small id="EmailHelp" class="form-text text-muted">
        Used to recover your password. We will never sell your email!
      </small>
      <div [hidden]="Email.valid || Email.pristine" class="alert alert-danger">
        A valid Email is required.
      </div>
    </div>

    <div class="form-group">
      <label for="Password">Password</label>
      <input type="password" name="Password" id="Password" [(ngModel)]="model.Password" #Password="ngModel" class="form-control" placeholder="Password" aria-describedby="PasswordHelp" required minlength="8" maxlength="255">
      <small id="PasswordHelp" class="form-text text-muted">
        At least 8 characters - one special character and one number.
      </small>
      <div [hidden]="Password.valid || Password.pristine" class="alert alert-danger">
        A valid Password is required.
      </div>
    </div>

    <div class="form-group">
      <label for="ConfirmPassword">Confirm Password</label>
      <input type="password" name="ConfirmPassword" id="ConfirmPassword" [(ngModel)]="model.ConfirmPassword" #ConfirmPassword="ngModel" class="form-control" placeholder="Confirm Password" aria-describedby="ConfirmPasswordHelp" required minlength="8" maxlength="255">
      <div [hidden]="ConfirmPassword.valid || ConfirmPassword.pristine" class="alert alert-danger">
        The Password and Confirm Password fields must match.
      </div>
    </div>

    <p>
      We use your email to help you reset your password. On a rare occasion, we may send news or promotions which you can cancel at any time.  We will never sell your email.
    </p>
    <p>
      Creating an account acknowledges agreement with our <a [routerLink]="'/terms'">Terms of Service</a> and <a [routerLink]="'/privacy'">Privacy Policy</a>.
    </p>

    <p class="text-right">
      <button type="submit" [disabled]="!registerForm.form.valid || isRegistering" class="form-button">
        <span [className]="isRegistering ? 'spinner-border spinner-border-sm' : ''"></span>
        {{isRegistering ? 'Here We Go!' : 'Register'}}
      </button>
    </p>

    <div class="alert alert-danger" role="alert" *ngIf="errors.length > 0">
      <ul>
        <li *ngFor="let error of errors">
          {{ error }}<br />
        </li>
      </ul>
    </div>

  </form>

</div>
