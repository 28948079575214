<div class="callout">
  <h1><a (click)="showHideAddMember()"><img src="../../assets/images/Icons/{{this.isAddMemberVisible ? 'Minus.png' : 'Plus.png'}}" width="20" height="25" /> Add Member</a></h1>
  
  <ng-container *ngIf="this.isAddMemberVisible">

    <label for="groupName">Send An Invite</label> <br />&nbsp;<br />
    <p>
      <span *ngIf="isMobile()"><a href="sms://?&body=Join%20me%20for%20a%20secure%20chat%20on%20Send%20It%20To%20Me%20%20https://www.senditto.me/invite/{{memberId}}"><img src="../../assets/images/Icons/SMS.png" height="35" alt="SMS/Text" /></a> &nbsp;</span>
      <a href="mailto:?subject=Join%20me%20for%20a%20secure%20chat%20on%20Send%20It%20To%20Me&body=I%20am%20inviting%20you%20to%20join%20me%20for%20a%20secure%20chat%20at%20https://www.senditto.me/invite/{{memberId}}%20&#x0D;&#x0A;"><img src="../../assets/images/Icons/Email.png" height="35" alt="Email" /></a> &nbsp;
      <a ngbTooltip="Link copied to clipboard." triggers="click" #p="ngbTooltip" [closeDelay]="2000" (click)="copyMessage('https://www.senditto.me/invite/' + memberId);p.open();"><img src="../../assets/images/Icons/Copy.png" height="35" alt="Copy to Clipboard" /></a> &nbsp;
      <a target="_blank" href="https://www.facebook.com/sharer/sharer.php?u=https://www.senditto.me/invite/{{memberId}}"><img src="../../assets/images/Icons/Facebook.png" height="35" alt="Facebook" /></a> &nbsp;
      <a target="_blank" href="https://www.twitter.com/intent/tweet?url=https://www.senditto.me/invite/{{memberId}}&text=Join%20me%20for%20a%20secure%20chat%20on%20SendItTo.me"><img src="../../assets/images/Icons/Twitter.png" height="35" alt="Twitter" /></a> &nbsp;
    </p>

    <!-- Add Group Member -->
    <form #addCrew="ngForm">
      <label for="groupName">Or Enter Someone's Member Id</label> &nbsp;
      <a class="mr-2" ngbTooltip="To add someone to your group, get their six character Member Id, or provide them with your Member Id: {{memberId}}" triggers="click:blur">
        <img src="../../assets/images/Icons/Help.png" height="14" />
      </a> <br />&nbsp;<br />

      <input type="text" name="GroupName" id="GroupName" [(ngModel)]="groupName" placeholder="ABC123" maxlength="6" /> &nbsp;
      <button (click)="add()" [disabled]="adding" class="form-button" type="button">
        <span [className]="adding ? 'spinner-border spinner-border-sm' : ''"></span>
        {{adding ? 'Adding...' : 'Add'}}
      </button>
      <ngb-alert *ngIf="addResultText" (close)="addResultText = null" [type]="isSuccess ? 'success' : 'danger'">
        <div [innerHTML]="addResultText"></div>
      </ngb-alert>
    </form>

  <p>&nbsp;</p>
  </ng-container>

</div>
