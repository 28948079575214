<div class="page">

  <router-outlet (activate)="onActivate($event)"></router-outlet>

</div>

<!--<div>{{memberCode}}</div>-->

<app-footer></app-footer>

