import { Component, OnInit } from '@angular/core';
import { MessagingService } from '../_services/messaging.service';
import { PlayerService } from '../_services/player.service';
import { Timer } from '../_shared/timer';
import { SharedCode } from '../_shared/sharedCode';
import { Title } from '@angular/platform-browser';

@Component({
  selector: 'app-group-request',
  templateUrl: './group-request.component.html',
  styleUrls: ['./group-request.component.css']
})
export class GroupRequestComponent implements OnInit {

  constructor(
    private messagingService: MessagingService,
    private playerService: PlayerService,
    private title: Title) {
    title.setTitle("SendItTo.me - Member Requests");
  }

  groupName: string; // New group member name
  addResultText: string;
  adding: boolean;
  isSuccess: boolean = true;
  memberId: string; // Logged in user's member id
  addingFromLink: boolean = false;

  isAddMemberVisible: boolean = false;
  
  ngOnInit() {
    this.playerService.getPlayerSummary()
      .subscribe(result => {
        this.memberId = result.value.groupName;
      });
  }

  ngAfterContentChecked() { // If there's a member id in local storage, the user followed a link to add a member
    var invitedMemberId = localStorage.getItem("newMemberId");
    if (!invitedMemberId) {
      return;
    }
    this.isAddMemberVisible = true;
    this.addingFromLink = true;
    this.groupName = invitedMemberId;
    this.add();
    localStorage.removeItem('newMemberId');
  }

  async add() {
    if (!this.groupName) {
      return;
    }

    this.adding = true;
    await new Timer().sleep(2000);

    this.messagingService.addGroupRequest(this.groupName)
      .subscribe(result => {
        if (result.success) {
          this.isSuccess = true;
          this.groupName = "";
          this.addResultText = SharedCode.addPlayerMessages(result.playerMessages);
          if (this.addingFromLink) {
            alert(SharedCode.addPlayerMessages(result.playerMessages, false));
          }
        } else {
          this.isSuccess = false;
          this.addResultText = result.errorMessage;
        }
      });
    this.adding = false;
    await new Timer().sleep(4000);
    this.addResultText = null;
  }

  isMobile() {
    return /Mobi/.test(window.navigator.userAgent);
  }

  // The copy function requires a textbox to be created. Create an invisible textbox and destroy it. 
  copyMessage(val: string) {
    const selBox = document.createElement('textarea');
    selBox.style.position = 'fixed';
    selBox.style.left = '0';
    selBox.style.top = '0';
    selBox.style.opacity = '0';
    selBox.value = val;
    document.body.appendChild(selBox);
    selBox.focus();
    selBox.select();
    document.execCommand('copy');
    document.body.removeChild(selBox);
  }

  public showHideAddMember(): void {
    this.isAddMemberVisible = !this.isAddMemberVisible;
  }

}
