<app-header></app-header>

<div class="callout">

  <h2><a onclick="history.back()"><img src="../../assets/images/Icons/Back.png" width="20" height="20" /> &nbsp;{{member?.name}}</a></h2>

  Member Id: {{member?.groupName}}<br />

  <p>
    <a [routerLink]="" (click)="remove(member.groupName)">{{member?.removing ? 'Removing...' : 'Remove'}}</a>
    <ngb-alert *ngIf="member.resultText" (close)="member.resultText = null" [type]="removeSuccessful ? 'success' : 'danger'">
      <div [innerHTML]="member.resultText"></div>
    </ngb-alert>
  </p>

</div>
