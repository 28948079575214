import { Component, OnInit } from '@angular/core';
import { MessagingService } from '../_services/messaging.service';
import { ChatMessage } from '../_models/chatMessage';
import { Router } from '@angular/router';

@Component({
  selector: 'app-chat-history',
  templateUrl: './chat-history.component.html',
  styleUrls: ['./chat-history.component.css']
})
export class ChatHistoryComponent implements OnInit {

  constructor(
    private messagingService: MessagingService,
    private router: Router) { }

  chatMessages: ChatMessage[];
  refreshInterval: any;

  ngOnInit() {
    this.getChatMessages();

    // Reload every n seconds
    this.refreshInterval = setInterval(() => {
      this.getChatMessages();
    }, 8000);
  }

  ngOnDestroy() {
    clearInterval(this.refreshInterval);
  }

  getChatMessages() {
    this.messagingService.getChatMessages()
    .subscribe(result => {
      this.chatMessages = result.value;
    });
  }

}
