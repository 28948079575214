<a name="Chat" id="Chat"></a>
<div class="callout">
  <h1>Chat</h1>

  <ol>

    <!-- If no chat messages -->
    <li>
      <p>
        <span style="font-size:small">
          To chat, add a Member using their <a [routerLink]="'/groups'">Member Id</a>.
        </span>
      </p>
      <ng-container *ngIf="this.chatMessages && this.chatMessages.length == 0"><p><strong>No Chat messages.</strong></p></ng-container>
    </li>

    <ng-container *ngFor="let chat of chatMessages">
      <li>
        <p>

          <button class="form-button" [routerLink]="['/chat', chat?.groupName]">Chat</button> &nbsp;

          <ng-container *ngIf="chat?.isSender">
            {{chat?.playerName}} >> <a [routerLink]="['/member', chat?.groupName]">{{chat?.name}}</a>
          </ng-container>
          <ng-container *ngIf="! chat?.isSender">
            <a [routerLink]="['/member', chat?.groupName]">{{chat?.name}}</a> >> {{chat?.playerName}}
          </ng-container>

          : <strong>{{chat?.message}}</strong> <br /><span class="small-text">({{chat?.time}})</span>

        </p>
      </li>
    </ng-container>

  </ol>
</div>
