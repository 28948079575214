import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from "@angular/common/http";
import { Observable } from 'rxjs';
import { AuthenticationService } from "./authentication.service";
import { Constants } from '../_shared/constants';
import { Dashboard } from '../_models/dashboard';
import { Result } from '../_models/result';
import { PlayerSummary } from '../_models/playerSummary';

@Injectable({ providedIn: 'root' })
export class PlayerService {

  constructor(
    private http: HttpClient,
    private authenticationService: AuthenticationService) { }

  getDashboard(): Observable<Result<Dashboard>> {

    let headers = new HttpHeaders({
      'Content-Type': 'application/json',
      'Authorization': 'Bearer ' + this.authenticationService.getToken()
    });

    return this.http.get<Result<Dashboard>>(
      Constants.ApiRoot + Constants.PlayerDashboardService, { 'headers': headers });
  }

  getPlayerSummary(groupName: string = ''): Observable<Result<PlayerSummary>> {
    let headers = new HttpHeaders({
      'Content-Type': 'application/json',
      'Authorization': 'Bearer ' + this.authenticationService.getToken()
    });

    var groupNameParam = (groupName != '') ? ('/' + groupName) : '';

    return this.http.get<Result<PlayerSummary>>(
      Constants.ApiRoot + Constants.PlayerSummaryService + groupNameParam, { 'headers': headers });
  }

}
