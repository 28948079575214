<app-main-sub-menu></app-main-sub-menu>

<div class="callout">
  <h1>We need your help</h1>
  <p>We are actively growing Send It To Me into a world-class, secure chat app... but we need your help!</p>
</div>

<div class="callout">

  <h1>Financial Contributions</h1>
  <p>If you like Send It To Me, please consider becoming a Patreon.</p>
  <p>
    <button (click)="patreon()" class="form-button" type="button">
      <img src="../../assets/images/Patreon.png" width="100" height="25" />
    </button>
  </p>

  <p>Send It To Me is currently a labor of love and none of us take a salary. We would love to have funds to make significant improvements to Send It To Me.</p>

  <p>If you like Send It To Me, and you'd like to be a part of our continued development, please consider becoming a Patreon.</p>

</div>

<div class="callout">

  <h1>Volunteer Opportunities</h1>
  <p>We're looking for volunteers for the following opportunities. If you have some free time and a passion for any of these areas, you can contact us on SendItTo.me by adding the Member Id: SITM01.</p>
  <p>We're more than happy to help you and mentor you in your personal growth as well.</p>

  <ul>
    <!--<li>Contribute to improve our <a href="https://www.SendItTo.me/wiki" target="_blank">Wiki</a> and <a href="https://support.SendItTo.me" target="_blank">Feedback</a> sites.<br />&nbsp;</li>-->
    <li>SEO - Search engine optimization, marketing and advertising.<br />&nbsp;</li>
    <li>Project Management.<br />&nbsp;</li>
    <li>UI/UX and Graphic Design.<br />&nbsp;</li>
    <li>QA - API testing, UI testing and regression test procedures.<br />&nbsp;</li>
    <li>Site Administration.<br />&nbsp;</li>
    <li>Software Development and Coding.<br />&nbsp;</li>
    <li>Customer support management.<br />&nbsp;</li>
    <li>Author/Writer for marketing material.<br />&nbsp;</li>
    <li>Photographer/Illustrator - either digital or traditional to create marketing graphics.<br />&nbsp;</li>
    <li>Other talented people to help.<br />&nbsp;</li>
  </ul>

</div>

<div class="callout">

  <h2>Thank You!</h2>

  <p>Thank you so much for your generosity!</p>

  <p>
    We'd love to hear from you. You can contact us on SendItTo.me by adding the Member Id: SITM01. Let us know if you'd like to volunteer, or say hello, request a feature, or report a bug.
  </p>

  <p>The Send It To Me team</p>

</div>
