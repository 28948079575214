<div class="fixed-bottom container" style="max-width: 900px; background-color: black;">
  <ngb-alert type="secondary" [dismissible]="false" style="background-color: black; border: none">
      <div class="row">
        <div class="col">
          <button class="form-button" routerLink="/tutorial/2">Back</button>
        </div>
        <div class="col">
          <button class="form-button" routerLink="/guest">Skip</button>
        </div>
        <div class="col">
          <button class="form-button" routerLink="/tutorial/4">Next</button>
        </div>
      </div>
  </ngb-alert>
</div>

<ng-template #popContent3_1>
  <div class="pop">
    <h2>Booty</h2>
    <p>This is your store. Most Missions require Booty that you can buy here.</p>
    <div class="text-right"><button class="form-button" (click)="closePopover(p3_1); openPopover(p3_2);">Ok</button></div>
    <h3>There is no escape.</h3>
  </div>
</ng-template>

<ng-template #popContent3_2>
  <div class="pop">
    <p>Booty also boosts your Battle strength. Each item adds to your Offense and Defense score. This makes you stronger when you fight other players.</p>
    <div class="text-right"><button class="form-button" (click)="closePopover(p3_2); openPopover(p3_3);">Ok</button></div>
  </div>
</ng-template>

<ng-template #popContent3_3>
  <div class="pop">
    <p>And some Booty has a Maintenance cost. These require you to have Assets to cover ongoing maintenance.</p>
    <div class="text-right"><button class="form-button" routerLink="/tutorial/4">Next</button></div>
  </div>
</ng-template>

<div class="header">
  <div class="main-menu">
    <ul>
      <li class="grayed-out"><img width="33" src="..\..\assets\images\Icons\Menus\Home.gif"><br>Port</li>
      <li class="grayed-out"><img width="25" src="..\..\assets\images\Icons\Menus\Tasks.gif"><br>Mission</li>
      <li class="grayed-out"><img width="27" src="..\..\assets\images\Icons\Menus\Fight.gif"><br>Battle</li>
      <li class="main-menu-selected" #p3_1="ngbPopover" [ngbPopover]="popContent3_1" placement="bottom" triggers="manual" [autoClose]="false"><img width="24" src="..\..\assets\images\Icons\Menus\Enhancements.gif"><br>Booty</li>
      <li class="grayed-out"><img width="31" src="..\..\assets\images\Icons\Menus\More.gif"><br>More</li>
    </ul>
  </div>
</div>
<div class="dashboard">
  <table width="100%" border="0">
    <tbody>
      <tr>
        <td align="right"><img width="15" height="15" src="../../assets/images/Icons/Credits.jpg"></td>
        <td align="right">Credits:</td>
        <td align="left" nowrap="">
          ç<span id="credits">2,500</span>
        </td>
        <td width="50%"></td>
        <td align="right"><img width="15" height="15" src="../../assets/images/Icons/Health.jpg"></td>
        <td align="right">Health:</td>
        <td align="left" nowrap="">
          <span id="healthPoints">100</span><span style="font-size:x-small">/100<br></span>
        </td>
      </tr>
      <tr>
        <td align="right"><img width="15" height="15" src="../../assets/images/Icons/Fights.jpg"></td>
        <td align="right">Fights:</td>
        <td align="left" nowrap="">
          <span id="fightPoints">5</span><span style="font-size:x-small">/5</span>
        </td>
        <td>
        </td>
        <td align="right"><img width="15" height="15" src="../../assets/images/Icons/Skill.jpg"></td>
        <td align="right">Skill:</td>
        <td align="left" nowrap="">0</td>
      </tr>
      <tr>
        <td align="right"><img width="15" height="15" src="../../assets/images/Icons/Tasks.jpg"></td>
        <td align="right">Missions:</td>
        <td align="left" nowrap="">
          <span id="taskPoints">20</span><span style="font-size:x-small">/20</span>
        </td>
        <td></td>
        <td align="right"></td>
        <td align="right"></td>
        <td align="left" nowrap=""></td>
      </tr>
      <tr><td align="left" colspan="7"><div class="progress-bar"><span style="width: 0%;"></span></div></td></tr>
      <tr><td align="center" colspan="7"> Level: 1 &nbsp; &nbsp; &nbsp; 0<span style="font-size:x-small">/10</span></td></tr>
    </tbody>
  </table>
</div>

<div class="action">
  <img class="action-image-top" src="../../assets/images/TableTop.jpg">
  <div class="action-inner">
    <h2>Stomper</h2>
    <div class="action-left"><img class="action-image" src="../../assets/images/Enhancements/Stomper.jpg"></div>
    <div class="action-right">
      <div>
        Owned: 0<br><span>
        Cost: ç120,000<br></span>
        Offense: +12<br>
        <div #p3_2="ngbPopover" [ngbPopover]="popContent3_2" placement="bottom" triggers="manual" [autoClose]="false">Defense: +10</div><br>
        <div #p3_3="ngbPopover" [ngbPopover]="popContent3_3" placement="bottom" triggers="manual" [autoClose]="false"><span>Maintenance: ç1,500</span></div><br>
        <br>&nbsp;<br><button class="form-button" type="button"><span class=""></span> Buy </button> &nbsp;
      </div>
    </div>
  </div>
  <img class="action-image-bottom" src="../../assets/images/TableBottom.jpg">
</div>

<div>
  <div class="action">
    <img class="action-image-top" src="../../assets/images/TableTop.jpg"><div class="action-inner">
      <h2>Heavy Artillery Tank </h2>
      <p><img class="action-image-small" src="../../assets/images/Icons/Locked.gif"><strong> LOCKED</strong></p>
    </div><img class="action-image-bottom" src="../../assets/images/TableBottom.jpg">
  </div>
</div>

<div class="submenu">
  <nav>
    <ul class="nav justify-content-center">
      <li class="nav-item">Weapons</li>
      <li class="nav-item">Ships</li>
      <li class="nav-item">Armor</li>
    </ul>
  </nav>
</div>
