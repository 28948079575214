<ng-container *ngIf="this.requests && this.requests.length > 0">
  <a name="Requests" id="Requests"></a>
  <div class="callout">
    <h1>Requests</h1>

    <ol>

      <li *ngFor="let request of requests">
        <h2>{{request?.name}}</h2>
        Member Id: {{request?.groupName}}<br />
        <p>Group Size: {{request?.groupSize}}</p>
        <p>
          <button *ngIf="!request?.hideButtons" (click)="confirmRequest(request?.groupName)" [disabled]="request?.confirming || request?.ignoring" class="form-button" type="button">
            <span [className]="request?.confirming ? 'spinner-border spinner-border-sm' : ''"></span>
            {{request?.confirming ? 'Confirming...' : 'Confirm'}}
          </button>
          &nbsp;
          <a [routerLink]="" *ngIf="!request?.hideButtons" (click)="ignore(request?.groupName)" [disabled]="request?.ignoring || request?.confirming">{{request?.ignoring ? 'Ignoring...' : 'Ignore'}}</a>
        </p>
        <ngb-alert *ngIf="request?.resultText" (close)="request.resultText = null" [type]="request.isSuccess ? 'success' : 'danger'">
          <div [innerHTML]="request?.resultText"></div>
        </ngb-alert>
      </li>

    </ol>

  </div>
</ng-container>
