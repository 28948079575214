import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from "@angular/common/http";
import 'rxjs/add/operator/map'
import { Constants } from '../_shared/constants';
import { Registration } from '../_models/registration';
import { Login } from '../_models/login';
import { Token } from '../_models/token';
import { Guest } from '../_models/guest';

@Injectable({ providedIn: 'root' })
export class AuthenticationService {

  private token: string;

  constructor(
    private http: HttpClient) {
    // See if JWT is already in local storage (user previously logged in)
    var currentUser = JSON.parse(localStorage.getItem(Constants.JwtKey));
    this.token = currentUser && currentUser.token;
  }

  public getToken() {
    return this.token;
  }

  login(user: Login) {
    let headers = new HttpHeaders({
      'Content-Type': 'application/json; charset=utf-8' });

    return this.http.post<Token>(Constants.ApiRoot + Constants.LoginService, user, { 'headers': headers });

    // Oddly, the token is set after login in the login.component and the register.component. Refactor opportunity. Should be set here.
  }

  logout(): void {
    this.token = null;
    //localStorage.clear(); // Clearing everything also removed record that they approved cookies, acknowledged facebook, etc. 
    localStorage.removeItem(Constants.JwtKey);
  }

  register(user: Registration) {
    let headers = new HttpHeaders({
      'Content-Type': 'application/json; charset=utf-8',
      'Authorization': 'Bearer ' + this.token
  });

    return this.http.post<Token>(Constants.ApiRoot + Constants.RegisterService, user, { 'headers': headers });
  }

  guest(user: Guest) {
    let headers = new HttpHeaders({
      'Content-Type': 'application/json; charset=utf-8'
    });

    return this.http.post<Token>(Constants.ApiRoot + Constants.GuestService, user, { 'headers': headers });
  }

  logoutGuest(): void {
    this.token = null;
    localStorage.removeItem(Constants.JwtKey);
  }

  isUserLoggedIn(): boolean {
    if (this.token && localStorage.getItem(Constants.JwtKey) != null)
      return true;

    return false;
  }

  getUserRole(): string {
    if (this.isUserLoggedIn()) {
      let claims = JSON.parse(atob(this.token.split('.')[1]));
      return claims.roles[1];
    }
    return "";
  }
}

