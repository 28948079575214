<div class="fixed-bottom container" style="max-width: 900px; background-color: black;">
  <ngb-alert type="secondary" [dismissible]="false" style="background-color: black; border: none">
      <div class="row">
        <div class="col">
          <button class="form-button" routerLink="/tutorial/6">Back</button>
        </div>
        <div class="col">
          <button class="form-button" routerLink="/guest">Skip</button>
        </div>
        <div class="col">
          <button class="form-button" routerLink="/tutorial/8">Next</button>
        </div>
      </div>
  </ngb-alert>
</div>

<ng-template #popContent7_1>
  <div class="pop">
    <h2>Buried Treasure</h2>
    <p>You’ll want to bury your credits before you go offline. Anything buried can’t be stolen when you’re away.</p>
    <div class="text-right"><button class="form-button" (click)="closePopover(p1); openPopover(p2);">Ok</button></div>
    <h3>You'll be visiting Davey Jone's locker.</h3>
  </div>
</ng-template>

<ng-template #popContent7_2>
  <div class="pop">
    <h2>Medic</h2>
    <p>See the Medic to heal your wounds after brutal Battles.</p>
    <div class="text-right"><button class="form-button" routerLink="/tutorial/8">Next</button></div>
  </div>
</ng-template>

<div class="header">
  <div class="main-menu">
    <ul>
      <li class="grayed-out"><img width="33" src="..\..\assets\images\Icons\Menus\Home.gif"><br>Port</li>
      <li class="grayed-out"><img width="25" src="..\..\assets\images\Icons\Menus\Tasks.gif"><br>Mission</li>
      <li class="grayed-out"><img width="27" src="..\..\assets\images\Icons\Menus\Fight.gif"><br>Battle</li>
      <li class="grayed-out"><img width="24" src="..\..\assets\images\Icons\Menus\Enhancements.gif"><br>Booty</li>
      <li class="main-menu-selected"><img width="31" src="..\..\assets\images\Icons\Menus\More.gif"><br>More</li>
    </ul>
  </div>
</div>
<div class="dashboard">
  <table width="100%" border="0">
    <tbody>
      <tr>
        <td align="right"><img width="15" height="15" src="../../assets/images/Icons/Credits.jpg"></td>
        <td align="right">Credits:</td>
        <td align="left" nowrap="">
          ç<span id="credits">2,500</span>
        </td>
        <td width="50%"></td>
        <td align="right"><img width="15" height="15" src="../../assets/images/Icons/Health.jpg"></td>
        <td align="right">Health:</td>
        <td align="left" nowrap="">
          <span id="healthPoints">100</span><span style="font-size:x-small">/100<br></span>
        </td>
      </tr>
      <tr>
        <td align="right"><img width="15" height="15" src="../../assets/images/Icons/Fights.jpg"></td>
        <td align="right">Fights:</td>
        <td align="left" nowrap="">
          <span id="fightPoints">5</span><span style="font-size:x-small">/5</span>
        </td>
        <td>
        </td>
        <td align="right"><img width="15" height="15" src="../../assets/images/Icons/Skill.jpg"></td>
        <td align="right">Skill:</td>
        <td align="left" nowrap="">0</td>
      </tr>
      <tr>
        <td align="right"><img width="15" height="15" src="../../assets/images/Icons/Tasks.jpg"></td>
        <td align="right">Missions:</td>
        <td align="left" nowrap="">
          <span id="taskPoints">20</span><span style="font-size:x-small">/20</span>
        </td>
        <td></td>
        <td align="right"></td>
        <td align="right"></td>
        <td align="left" nowrap=""></td>
      </tr>
      <tr><td align="left" colspan="7"><div class="progress-bar"><span style="width: 0%;"></span></div></td></tr>
      <tr><td align="center" colspan="7"> Level: 1 &nbsp; &nbsp; &nbsp; 0<span style="font-size:x-small">/10</span></td></tr>
    </tbody>
  </table>
</div>

<div class="callout">
  <h2 style="color: #357ec7;">Player</h2> See how your player stacks up. You can also allocate your skill points here. <br>&nbsp;<br>
  <h2 style="color: #357ec7;">Assets</h2> Enter the black market to establish a regular income. <br>&nbsp;<br>
  <div #p1="ngbPopover" [ngbPopover]="popContent7_1" placement="top" triggers="manual" [autoClose]="false"><h2 style="color: #357ec7;">Buried Treasure</h2> Pirates love to bury their treasure, and for good reason too.</div> <br>&nbsp;<br>
  <div #p2="ngbPopover" [ngbPopover]="popContent7_2" placement="top" triggers="manual" [autoClose]="false"><h2 style="color: #357ec7;">Medic</h2> After getting beat up in a long battle, stop here for a little R&amp;R.</div> <br>&nbsp;<br>
  <h2 style="color: #357ec7;">Crew</h2> Communicate with your crew members and add other pirates to your crew. <br>&nbsp;<br>
  <h2 style="color: #357ec7;">Achievements</h2> See your various accomplishments as a pirate. Try to earn them all. <br>&nbsp;<br></div>
