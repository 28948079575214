import { Component, AfterViewInit, ViewChild, TemplateRef } from '@angular/core';
import { NgbPopover } from '@ng-bootstrap/ng-bootstrap';
import { Title } from '@angular/platform-browser';

@Component({
  selector: 'app-page06',
  templateUrl: './page06.component.html',
  styleUrls: ['./page06.component.css'],
})
export class Page06Component implements AfterViewInit {

  @ViewChild(NgbPopover) p1: NgbPopover;
  @ViewChild(TemplateRef) popContent6_1: any;

  constructor(
    private title: Title) {
    title.setTitle("SendItTo.me - Tutorial");
  }

  ngAfterViewInit() {
    this.p1.ngbPopover = this.popContent6_1;
    this.openPopover(this.p1);
  }

  openPopover(p: NgbPopover): void {
    p.open();
  }

  closePopover(p: NgbPopover): void {
    p.close();
  }

}
