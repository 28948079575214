import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute, ParamMap } from '@angular/router';
import { switchMap } from 'rxjs/operators';
import { Title } from '@angular/platform-browser';
import { AuthenticationService } from '../_services/authentication.service';
import { PlayerService } from '../_services/player.service';
import { GroupMember } from '../_models/groupMember';
import { MessagingService } from '../_services/messaging.service';
import { SharedCode } from '../_shared/sharedCode';
import { Timer } from '../_shared/timer';

@Component({
  selector: 'app-group-member',
  templateUrl: './group-member.component.html',
  styleUrls: ['./group-member.component.css']
})
export class GroupMemberComponent implements OnInit {

  groupName: string = '';
  member: GroupMember = new GroupMember();
  removeSuccessful: boolean;


  constructor(
    private playerService: PlayerService,
    private messagingService: MessagingService,
    private authenticationService: AuthenticationService,
    private route: ActivatedRoute,
    private router: Router,
    private title: Title) {
    title.setTitle("SendItTo.me - Group Member");
  }

  ngOnInit() {
    try {
      this.route.paramMap.pipe(
        switchMap((params: ParamMap) =>
          params.get('id'))
      ).subscribe(val => this.groupName += val);
    } catch (e) {
    }

    this.messagingService.getGroupMember(this.groupName)
      .subscribe(result => {
        this.member = result.value;
      });
  }

  async remove(groupName: string) {
    if (!groupName) {
      return;
    }

    if (!confirm('Do you want to remove the member ' + this.member.name)) {
      return;
    }

    this.messagingService.removeGroupMember(groupName)
      .subscribe(result => {
        if (result.success) {
          this.member.resultText = SharedCode.addPlayerMessages(result.playerMessages);
        } else {
          this.removeSuccessful = false;
          this.member.resultText = result.errorMessage;
        }
      });
    this.member.removing = false;

    this.member.resultText = null;

    this.router.navigate(['home']);
  }

}
