import { Component, OnInit } from '@angular/core';
import { MessagingService } from '../_services/messaging.service';
import { Router } from '@angular/router';
import { GroupMember } from '../_models/groupMember';
import { Title } from '@angular/platform-browser';
import { DatastoreService } from '../_services/datastore.service';
import { Datastore } from '../_models/datastore';

@Component({
  selector: 'app-groups',
  templateUrl: './groups.component.html',
  styleUrls: ['./groups.component.css']
})
export class GroupsComponent implements OnInit {

  data: Datastore;
  members: GroupMember[];
  groupName: string;
  refreshInterval: any;

  constructor(
    private messagingService: MessagingService,
    private router: Router,
    private datastore: DatastoreService,
    private title: Title) {
    title.setTitle("SendItTo.me - Home");

    this.datastore.register().subscribe((data) => {
      this.data = data;
      if (this.data.isMemberListStale) {
        this.updateGroupList();
      }
    });
  }

  ngOnInit() {
    this.updateGroupList();

    // Reload every n seconds
    this.refreshInterval = setInterval(() => {
      this.updateGroupList();
    }, 12000);
  }

  ngOnDestroy() {
    clearInterval(this.refreshInterval);
  }

  private updateGroupList() {
    this.messagingService.getGroupMembers()
      .subscribe(result => {
        this.members = result.value;
      });
  }

  goToChat(groupName: string): void {
    this.router.navigateByUrl('/chat/' + groupName);
  }

}
