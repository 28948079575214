<app-header></app-header>

<app-main-sub-menu></app-main-sub-menu>

<div class="callout">
  <h2>FAQ</h2>
</div>

<div class="callout">

  <ol>
    <li *ngFor="let item of help">
      <h2>{{item?.question}}</h2>
      {{item?.answer}}
    </li>
  </ol>

</div>
