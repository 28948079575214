import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { Title } from '@angular/platform-browser';
import { AuthenticationService } from '../_services/authentication.service';
import { PlayerService } from '../_services/player.service';
import { PlayerSummary } from '../_models/playerSummary';
import { DatastoreService } from '../_services/datastore.service';
import { Datastore } from '../_models/datastore';

@Component({
  selector: 'app-player',
  templateUrl: './player.component.html',
  styleUrls: ['./player.component.css']
})
export class PlayerComponent implements OnInit {

  data: Datastore;
  actionString: string;
  isGuest: boolean = true;
  summary: PlayerSummary;

  constructor(
    private authenticationService: AuthenticationService,
    private playerService: PlayerService,
    private datastore: DatastoreService,
    private route: ActivatedRoute,
    private router: Router,
    private title: Title) {
    title.setTitle("SendItTo.me - Profile");

    this.datastore.register().subscribe((data) => {
      this.data = data;
      this.isGuest = this.data.role == "Guest";
      this.actionString = this.data.role === "" ? "" : (this.data.role === "Guest" ? "Create Account" : "Logout");
    });
  }

  ngOnInit() {
    this.playerService.getPlayerSummary()
      .subscribe(result => {
        this.summary = result.value;
      });
  }

  action() {
    if (this.data.role === "Guest") {
      this.router.navigateByUrl('/register');
    } else {
      this.authenticationService.logout();

      // Notify all watchers that the user has logged out
      this.datastore.setMemberCode('');

      // Using location.replace to reload the entire page for login/logout
      // This ensures the Xamarin web view will refresh and get the updated member code.
      location.replace('/');
    }
  }

}
