import { Component, OnInit } from '@angular/core';
import { MessagingService } from '../_services/messaging.service';
import { Help } from '../_models/help';
import { Title } from '@angular/platform-browser';

@Component({
  selector: 'app-help',
  templateUrl: './help.component.html',
  styleUrls: ['./help.component.css']
})
export class HelpComponent implements OnInit {

  constructor(
    private messagingService: MessagingService,
    private title: Title) {
    title.setTitle("SendItTo.me - Help");
  }

  help: Help[];

  ngOnInit() {
    this.messagingService.getHelp()
      .subscribe(result => {
        this.help = result.value;
      });
  }

}
