<script src="platform.js"></script>

<app-main-sub-menu></app-main-sub-menu>

<div class="callout">
  <h1>Troubleshooting</h1>
  <p>The following information may be useful in troubleshooting issues with the Send It To Me application.</p>
  <p>This information is only available on your device and is never sent to Send It To Me.</p>
</div>

<div class="callout">

  <h1>Device Information</h1>
  <p>User Agent: {{userAgent}}</p>
  <!--{{value}}-->
</div>
