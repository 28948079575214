<div class="callout">
  <h1>Members</h1>

  <ng-container *ngIf="this.members && this.members.length > 0">
    <div *ngFor="let member of members">
      <h2><a [routerLink]="['/member', member?.groupName]">{{member?.name}}</a></h2>
      <p>
        <button class="form-button" (click)="goToChat(member?.groupName)"><span *ngIf="member?.unreadMessagesFrom > 0" style="font-size: 85%" class="badge badge-primary"> {{member?.unreadMessagesFrom}}</span> Chat</button> &nbsp; &#8594; <span [innerHTML]="member?.latestMessageFrom"></span>
      </p><br />
    </div>
  </ng-container>

</div>
