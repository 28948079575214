import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { AuthenticationService } from '../_services/authentication.service';

@Component({
  selector: 'app-main-sub-menu',
  templateUrl: './main-sub-menu.component.html',
  styleUrls: ['./main-sub-menu.component.css']
})
export class MainSubMenuComponent implements OnInit {

  constructor(
    private authenticationService: AuthenticationService,
    private router: Router) {
  }

  ngOnInit() {
  }

  isLoggedIn(): boolean {
    return this.authenticationService.isUserLoggedIn();
  }
}
