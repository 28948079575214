import { Component, OnInit } from '@angular/core';
import { MessagingService } from '../_services/messaging.service';
import { GroupMember } from '../_models/groupMember';
import { SharedCode } from '../_shared/sharedCode';
import { Timer } from '../_shared/timer';
import { DatastoreService } from '../_services/datastore.service';

@Component({
  selector: 'app-group-requests',
  templateUrl: './group-requests.component.html',
  styleUrls: ['./group-requests.component.css']
})
export class GroupRequestsComponent implements OnInit {

  requests: GroupMember[];

  constructor(
    private messagingService: MessagingService,
    private datastoreService: DatastoreService ) {
  }

  ngOnInit() {
    this.messagingService.getGroupRequests()
      .subscribe(result => {
        this.requests = result.value;
      });
  }

  public async confirmRequest(groupName: string) {
    var member = this.requests.find(x => x.groupName == groupName);

    member.confirming = true;
    await new Timer().sleep(2000);

    this.messagingService.confirmGroupMember(groupName)
      .subscribe(result => {
        if (result.success) {
          // Notify all watchers of the group list to refresh
          this.datastoreService.memberListIsStale();

          member.resultText = SharedCode.addPlayerMessages(result.playerMessages);
          member.isSuccess = true;
        } else {
          member.resultText = result.errorMessage;
          member.isSuccess = false;
        }
    });

    member.confirming = false;
    member.hideButtons = true;
    await new Timer().sleep(4000);
    member.resultText = null;

    this.requests = this.requests.filter(x => x.groupName !== groupName);
  }

  public async ignore(groupName: string) {
    var member = this.requests.find(x => x.groupName == groupName);

    if (! confirm('Do you want to ignore the member request from ' + member.name)) {
      return;
    }

    member.ignoring = true;
    await new Timer().sleep(2000);

    this.messagingService.removeGroupMember(groupName)
      .subscribe(result => {

        if (result.success) {
          member.resultText = SharedCode.addPlayerMessages(result.playerMessages);
          member.isSuccess = true;
        } else {
          member.resultText = result.errorMessage;
          member.isSuccess = false;
        }
    });

    member.ignoring = false;
    member.hideButtons = true;
    await new Timer().sleep(4000);
    member.resultText = null;

    this.requests = this.requests.filter(x => x.groupName !== groupName);
  }

}
