<app-main-sub-menu></app-main-sub-menu>

<div class="container-fluid">
  <div class="row align-items-center">
    <div class="col-xs-5 col-sm-6 col-med-6 col-12" style="min-width: 325px;">
      <p><a [routerLink]="'/home'"><img src="../../assets/images/Logo.png" alt="SendItTo.me Logo" style="height: 60px;" /></a></p>
    </div>
  </div>
</div>

<div class="callout">
  <h1>Terms of Service</h1>
  <p>Last updated: May 28, 2021</p>
  <p>SendItTo.me is a United States company. We are patriotic and love our Country. We strongly support the Freedom of Speech protections covered by the First Amendment of the Constitution of the United States.</p>
  <p>Unfortunately, we believe these protections are under attack. We have all seen numerous concerning news stories regarding significant encroachment into the Freedom of Speech by big-government, combined with increased sensorship by big-tech companies.</p>
  <p>SendItTo.me was created in response to these concerns. Our mission is to provide the highest level of freedom of speech to our members allowed by the Constitution of the United States. Unfortunately, not all countries provide these same protections on speech.</p>
</div>

<div class="callout">
  <h1>You must be a US Person</h1>
  <p>Free Speech is the cornerstone of our democracy. Free Speech allows Americans to: 1) Say what we want 2) To whom we want 3) and To communicate confidentially 4) Without governmental intervention. That is why SendItTo.me allows you to say what you want, even if your opinion is unpopular.</p>
</div>

<div class="callout">
  <h1>You must be 17 years of age or older</h1>
  <p>Of course one could still loose friends, or even a job for what one says, so prudence on our platform is highly encouraged, but that is up to you.</p>
  <p>It's important to note that the Freedom of Speech is <strong>not all-inclusive</strong>. Many Constitutional Scholars agree that there are several exclusions as to what is protected by the First Ammendment.</p>
</div>

<div class="callout">
  <h1>The following are expressly prohibited on our platform and we denounce any such speech.</h1>
  * Obscenities<br />
  * Pornography<br />
  * Child Pornography<br />
  * Defamation<br />
  * Threats<br />
  * Blackmail<br />
  * Solicitation to commit crime<br />
  * Fraud<br />
  * Incitement of lawless action<br />
  * Fighting words<br />
  * Treason<br />
  * Any other illicit, illegal, abusive, harmful, violent or inflammatory words.<br />

  <p>Since all messages are encrypted when they are transmitted and stored, we do not read, filter, parse, police, block or in any way interact with your messages. SendItTo.me strictly acts as a conduit for communication, in the same way that a phone line or cable line are conduits.</p>
</div>

<div class="callout">
  <h1>SendItTo.me, Rhino Monkey Software, its staff, employees and volunteers are not responsible or liable for the content of any messages sent or received using our platform.</h1>
</div>

<div class="callout">
  <h1>The sender of a message assumes all responsibility, as well as liability, for the contents of any messages they send.</h1>
  <p>As SendItTo.me does not in any way read or parse messages sent, the receiver of a message must take on the legal obligation to report any criminal activity.</p>
</div>

<div class="callout">
  <h1>Creating a SendItTo.me account, as well as sending a message, acknowledges agreement with our Terms of Service and Privacy Policy.</h1>
</div>

<div class="callout">
  <h2>Changes to these Terms of Service</h2>
  <p>We may update these Terms of Service from time to time. We will notify you of any changes by posting the new Terms of Service on this page.</p>
  <p>We will update the "Last updated" date at the top of these Terms of Service.</p>
  <p>You are advised to review these Terms of Service periodically for any changes. Changes to these Terms of Service are effective when they are posted on this page.</p>

</div>

<div class="callout">
  <h2>Contact Us</h2>
  <p>If you have any questions about these Terms of Service, you can contact us on SendItTo.me by adding the Member Id: SITM01</p>
</div>
