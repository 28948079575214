export class SharedCode {

  public static addPlayerMessages(messages: string[], addLineEndings: boolean = true): string {
    var result = "";
    messages.forEach(message => {
      result += message;
      if (addLineEndings) {
        result +="<br />"
      }
    })
    return result;
  }

}
