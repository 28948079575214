import { environment } from '../../environments/environment';

export class Constants {

public static get ApiRoot(): string {
    return environment.apiRoot;

    // PROD
    //return "/";
    // BETA
    //public static ApiRoot = "/beta/";
    // DEV
    //public static ApiRoot = "localhost:10000/";
  }

  public static JwtKey: string = "currentUser";

  public static RoleEveryone: string = "Everyone";
  public static RoleGuest: string = "Guest";
  public static RoleRegistered: string = "Registered";
  public static RoleMember: string = "Member";
  public static RoleAdmin: string = "Admin";

  // Services
  public static GuestService: string = "api/accounts/guest";
  public static RegisterService: string = "api/accounts/register";
  public static LoginService: string = "api/accounts/login";

  public static PlayerDashboardService: string = "api/members/dashboard";
  public static PlayerSummaryService: string = "api/members/summary";

  public static GroupMemberService: string = "api/groups/";
  public static GroupMemberRequestsService: string = "api/groups/requests";

  public static ChatHistoryService: string = "api/messages/chat";
  public static HelpService: string = "api/messages/help";
  public static MessagingSystemMessagesService: string = "api/messages/systemMessages";
}

