import { Component, OnInit } from '@angular/core';
import { AuthenticationService } from '../_services/authentication.service';
import { Constants} from '../_shared/constants';
import { Login } from '../_models/login';
import { DatastoreService } from '../_services/datastore.service';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.css']
})
export class LoginComponent implements OnInit {

  model: Login = new Login("", "");
  errors: string[];
  isLoggingIn: boolean = false;

  private readonly jwtKey: string = Constants.JwtKey;

  constructor(
    private authenticationService: AuthenticationService,
    private datastore: DatastoreService
  ) { }

  ngOnInit() {
    this.errors = [];
  }

  loginUser() {
    this.isLoggingIn = true;
    this.errors = [];

    this.authenticationService.login(this.model).subscribe(
      success => {
        // Save JWT to local storage to keep user logged in between sessions
        localStorage.setItem(this.jwtKey, JSON.stringify({ username: this.model.Email, token: success.token }));

        // Notify all watchers that the user has logged out
        this.datastore.setMemberCode('I logged In');

        // Using location.replace to reload the entire page for login/logout
        // This ensures the Xamarin web view will refresh and get the updated member code.
        location.replace('/');
      },
      error => {
        this.isLoggingIn = false;
        this.errors.push('The Email and Password you entered do not match our records.');
      }

    );

  }

}
