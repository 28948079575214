  <!-- Unauthenticated menu -->
  <div class="callout">

    <div class="submenu">
      <nav>
        <ul class="nav justify-content-center">
          <li class="nav-item"><a [routerLink]="'/home'">Home</a>&nbsp;</li>
          <li *ngIf="! isLoggedIn()" class="nav-item"><a [routerLink]="'/about'">About</a>&nbsp;</li>
          <!--<li *ngIf="isLoggedIn()" class="nav-item"><a [routerLink]="" (click)="groups()">Members</a>&nbsp;</li>-->
          <li *ngIf="isLoggedIn()" class="nav-item"><a [routerLink]="'/member'">Profile</a>&nbsp;</li>

          <li class="nav-item"><a [routerLink]="'/contribute'">Contribute</a>&nbsp;</li>
          <!--<li class="nav-item"><a [routerLink]="'/store'">Store</a>&nbsp;</li>
          <li class="nav-item"><a href="https://www.senditto.me/wiki" target="_blank">Wiki/Help</a></li>
          <li class="nav-item"><a href="https://support.senditto.me" target="_blank">Feedback</a></li>-->
        </ul>
      </nav>
    </div>

  </div>
