import { HttpErrorResponse, HttpResponse, HttpEvent, HttpInterceptor, HttpHandler, HttpRequest } from "@angular/common/http";
import { tap } from 'rxjs/operators';
import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { Router } from "@angular/router";
import { AuthenticationService } from "../_services/authentication.service";

@Injectable()
export class AuthInterceptor implements HttpInterceptor {

  constructor(
    private router: Router,
    private authentication: AuthenticationService) {
  }

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    // Handle all incoming HTTP requests here

    // Handle all responses (from API calls) here
    return next.handle(request).pipe(tap((event: HttpEvent<any>) => {
      if (event instanceof HttpResponse) {
        // do stuff with response if you want
      }
    }, (err: any) => {
      if (err instanceof HttpErrorResponse) {
        // Fix for issue in which whenever a 401 was returned, would show a Server login, not this app login.
        // API was updated to only return 400s instead of 401s. So if 400 response from API, log user out. 
        if (err.status === 400 && err.error == "Unauthorized") {
          this.authentication.logout();
          this.router.navigateByUrl('/home');
        }
      }
    }));
  }
}
