<app-main-sub-menu></app-main-sub-menu>

<app-user-management></app-user-management>

<div class="callout">
  <br />
  <div class="row">
    <div class="col-sm-3">
      <p><a href="https://testflight.apple.com/join/m9MRZi6z" target="_blank"><img src="../../assets/images/AppleStore-Beta.png" alt="Available on the Apple Store" height="45" /></a></p>
    </div>
    <div class="col-sm-9 text-right">
      <a href="https://stats.uptimerobot.com/8KqyVHjmL2" target="_blank"><img src="../../assets/images/Uptime.png" alt="Uptime" height="35" /></a>&nbsp;<a href="https://www.sitelock.com/verify.php?site=senditto.me" target="_blank"><img src="../../assets/images/SiteLock.png" alt="SiteLock" height="35" /></a>
    </div>
  </div>

  <h2>Secure. Encrypted. Reliable.</h2>

  <p>I'm a free-speech chat app focusing on YOUR privacy. I have no advertising, no third-party marketing, and I will <strong>never</strong> share, sell or spy on your messages!</p>

  <h1>Chat done right!</h1>


  <p><strong>Like you, we're tired of big government and big tech's spying and censorship!</strong></p>

  <p>
    <strong>SendItTo.me</strong> was designed from the ground up to be secure.
    All messages are securely encrypted from the time they leave your device until they reach the recipient.
  </p>
  <p>We will never share, sell or spy on your messages. We take your privacy seriously.</p>

  <h2>Always chat for free!</h2>
  <p>
    Since we never sell your data, we rely on donations and subscriptions to keep the lights on.
    If you like SendItTo.me, and we know you will, you can choose to support us later. And if not,
    that's ok too. You can always chat for free.
  </p>
  <h1>Happy Chatting!</h1>
</div>

  <br /> &nbsp; <br />
