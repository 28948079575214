<app-main-sub-menu></app-main-sub-menu>

<div class="container-fluid">
  <div class="row align-items-center">
    <div class="col-xs-5 col-sm-6 col-med-6 col-12" style="min-width: 325px;">
      <p><a [routerLink]="'/home'"><img src="../../assets/images/Logo.png" alt="SendItTo.me Logo" style="height: 60px;" /></a></p>
    </div>
  </div>
</div>

<div class="callout">

  <h2>Create your User Name</h2>
  <p>This is how other users will identify you, so your name must be unique. We recommend you don't use personally identifiable information such as your real name, etc.</p>
  <p>Creating an account acknowledges agreement with our <a [routerLink]="'/terms'">Terms of Service</a> and <a [routerLink]="'/privacy'">Privacy Policy</a>.</p>

  <br />

  <form (ngSubmit)="guest()" #guestForm="ngForm">

    <div class="form-group">
      <!--<label for="UserName">User Name</label>-->
      <input type="text" name="UserName" id="UserName" [(ngModel)]="model.UserName" #UserName="ngModel" class="form-control" placeholder="User Name" aria-describedby="UserNameHelp" required minlength="3" maxlength="25">
      <small id="UserNameHelp" class="form-text text-muted">
        User Names must be "G" rated.
      </small>
      <div [hidden]="UserName.valid || UserName.pristine" class="alert alert-danger">
        A valid User Name is required.
      </div>
    </div>

    <p class="text-right">
      <input class="form-button" type="button" (click)="cancel()" value="Cancel"> &nbsp;

      <button type="submit" [disabled]="!guestForm.form.valid || isRegistering" class="form-button">
        <span [className]="isRegistering ? 'spinner-border spinner-border-sm' : ''"></span>
        {{isRegistering ? 'Let\'s Chat!' : 'Start!'}}
      </button>
    </p>

    <div class="alert alert-danger" role="alert" *ngIf="errors.length > 0">
      <ul>
        <li *ngFor="let error of errors">
          {{ error }}
        </li>
      </ul>
    </div>

  </form>

</div>
