import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { Title } from '@angular/platform-browser';

@Component({
  selector: 'app-terms',
  templateUrl: './terms.component.html',
  styleUrls: ['./terms.component.css']
})
export class TermsComponent implements OnInit {

  constructor(
    private router: Router,
    private title: Title) {
    title.setTitle("SendItTo.me - Terms of Service");
  }

  ngOnInit() {
  }

}
