import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from "@angular/common/http";
import { Observable } from 'rxjs';
import { AuthenticationService } from "./authentication.service";
import { Constants } from '../_shared/constants';
import { Help } from '../_models/help';
import { GroupMember } from '../_models/groupMember';
import { ChatMessage } from '../_models/chatMessage';
import { Result } from '../_models/result';
import { Chat } from '../_models/chat';

@Injectable({ providedIn: 'root' })
export class MessagingService {

  constructor(
    private http: HttpClient,
    private authenticationService: AuthenticationService) { }

  getSystemMessages(): Observable<Result<string[]>> {

    let headers = new HttpHeaders({
      'Content-Type': 'application/json',
      'Authorization': 'Bearer ' + this.authenticationService.getToken()
    });

    return this.http.get<Result<string[]>>(
      Constants.ApiRoot + Constants.MessagingSystemMessagesService, {'headers': headers});
  }

  getHelp(): Observable<Result<Help[]>> {

    let headers = new HttpHeaders({
      'Content-Type': 'application/json',
      'Authorization': 'Bearer ' + this.authenticationService.getToken()
    });

    return this.http.get<Result<Help[]>>(
      Constants.ApiRoot + Constants.HelpService, { 'headers': headers });
  }

  getGroupMembers(): Observable<Result<GroupMember[]>> {

    let headers = new HttpHeaders({
      'Content-Type': 'application/json',
      'Authorization': 'Bearer ' + this.authenticationService.getToken()
    });

    return this.http.get<Result<GroupMember[]>>(
      Constants.ApiRoot + Constants.GroupMemberService, { 'headers': headers });
  }

  getGroupMember(groupName: string): Observable<Result<GroupMember>> {

    let headers = new HttpHeaders({
      'Content-Type': 'application/json',
      'Authorization': 'Bearer ' + this.authenticationService.getToken()
    });

    return this.http.get<Result<GroupMember>>(
      Constants.ApiRoot + Constants.GroupMemberService + '/' + groupName, { 'headers': headers });
  }

  getGroupRequests(): Observable<Result<GroupMember[]>> {

    let headers = new HttpHeaders({
      'Content-Type': 'application/json',
      'Authorization': 'Bearer ' + this.authenticationService.getToken()
    });

    return this.http.get<Result<GroupMember[]>>(
      Constants.ApiRoot + Constants.GroupMemberRequestsService, { 'headers': headers });
  }

  confirmGroupMember(groupName: string): Observable<Result<Result<any>>> {

    let headers = new HttpHeaders({
      'Content-Type': 'application/json',
      'Authorization': 'Bearer ' + this.authenticationService.getToken()
    });

    return this.http.post<Result<Result<any>>>(
      Constants.ApiRoot + Constants.GroupMemberService, '"' + groupName + '"', { 'headers': headers });
  }

  addGroupRequest(groupName: string): Observable<Result<Result<any>>> {
    let headers = new HttpHeaders({
      'Content-Type': 'application/json',
      'Authorization': 'Bearer ' + this.authenticationService.getToken()
    });

    return this.http.post<Result<Result<any>>>(
      Constants.ApiRoot + Constants.GroupMemberRequestsService, '"' + groupName + '"', { 'headers': headers });
  }

  removeGroupMember(groupName: string): Observable<Result<string>> {
    let headers = new HttpHeaders({
      'Content-Type': 'application/json',
      'Authorization': 'Bearer ' + this.authenticationService.getToken()
    });

    return this.http.delete<Result<string>>(
      Constants.ApiRoot + Constants.GroupMemberService + '/' + groupName, { 'headers': headers });
  }

  getChatMessages(): Observable<Result<ChatMessage[]>> {

    let headers = new HttpHeaders({
      'Content-Type': 'application/json',
      'Authorization': 'Bearer ' + this.authenticationService.getToken()
    });

    return this.http.get<Result<ChatMessage[]>>(
      Constants.ApiRoot + Constants.ChatHistoryService, { 'headers': headers });
  }

  getChatMessagesFromPlayer(groupName: string): Observable<Result<ChatMessage[]>> {

    let headers = new HttpHeaders({
      'Content-Type': 'application/json',
      'Authorization': 'Bearer ' + this.authenticationService.getToken()
    });

    return this.http.get<Result<ChatMessage[]>>(
      Constants.ApiRoot + Constants.ChatHistoryService + '/' + groupName, { 'headers': headers });
  }

  sendChatMessage(chat: Chat): Observable<Result<string>> {

    let headers = new HttpHeaders({
      'Content-Type': 'application/json',
      'Authorization': 'Bearer ' + this.authenticationService.getToken()
    });

    return this.http.post<Result<string>>(
      Constants.ApiRoot + Constants.ChatHistoryService, chat, { 'headers': headers });
  }

}
