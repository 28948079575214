export class GroupMember {

  constructor(
    public name: string = "",
    public groupName: string = "",
    public groupSize: number = 0,
    public unreadMessagesFrom: number = 0,
    public latestMessageFrom: string = "",

    public confirming: boolean = false,
    public ignoring: boolean = false,
    public removing: boolean = false,
    public hideButtons: boolean = false,
    public isSuccess: boolean = true,
    public resultText: string = "") { }
}
