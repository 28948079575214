<app-main-sub-menu></app-main-sub-menu>

<app-user-management></app-user-management>

<div class="callout">
  <h1>How It Works</h1>
  <p>SendItTo.me is a free-speech chat app focusing on YOUR privacy. There is no advertising, no third-party marketing, and we will <strong>never</strong> share, sell or spy on your messages!</p>
  <p>
    Communication is essential. So much so that it's explicitly protected by the First Amendment under the Constitution of the United States.
    But our freedom of speech is under attack. It's being eroded. Big Government continues to encroach on free speech. Big Tech companies are blocking or shadow banning users from their platforms for expressing views different than theirs.
  </p>
  <p>
    We are going through unprecedented times right now. From toilet paper to ammunition, we've recently seen inconsistencies with the supply chain.
    Free communication is especially important during times of crisis.
  </p>
  <p>
    The freedom of speech allows you to: 1) Say what you want 2) To whom you want 3) and to communicate confidentially.
  </p>
  <p>
    But more and more, "non-protected" speech is being redefined as anything that descents from the social mob.
    Facebook and Twitter allow us to easily communicate with tens or thousands of people, and sometimes that's great.
    But many conversations should have the same privacy as if you were both sitting in your living room, with no one listening, monitoring, or using every word to try to sell you something.
    Maybe you want to talk with your husband about your doctor appointment, your mother, or...to quote a famous singer, your crazy ex-lover.
  </p>

  <h2>Our Future Vision</h2>
  <p>
    We are in early design discussions to develop a system that will provide peer-to-peer communication throughout the country,
    without the need for cell towers, the internet, phone lines, satellites, or even power grids. We are in the early design phase of developing a small hand-held device that
    provides ground based communication without the need for any additional infrastructure. With a small solar panel, this device could provide much needed communication
    in times of crisis.
  </p>
  <h2>Summary</h2>
  <p>
    We are a small company in the midst of establishing a foothold in the land of giants. Thank you for trusting us with your communication needs.
    Rest assured that we will do everything within our power to provide the absolute best service that we can.
  </p>
  <p>
    To conclude, be kind to each other. Use our platform to speak warmly and kindly. Reconnect with someone that you haven't spoken to in a while.
  </p>
  <p>Tell someone that you love them.</p>

  <p>
    And be careful out there.<br />
    The SendItTo.me team
  </p>

</div>

