import { Component, OnInit } from '@angular/core';
import { Title } from '@angular/platform-browser';

@Component({
  selector: 'app-port',
  templateUrl: './port.component.html',
  styleUrls: ['./port.component.css']
})
export class PortComponent {

  constructor(
    private title: Title) {
    title.setTitle("SendItTo.me - Chat Done Right!");
  }

}

