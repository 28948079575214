import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { Datastore } from '../_models/datastore';
import { Constants } from '../_shared/constants';
import { AuthenticationService } from '../_services/authentication.service';
import { ModalService } from '../_services/modal.service';

@Injectable({ providedIn: 'root' })
export class DatastoreService {

  private datastore: BehaviorSubject<Datastore> =
    new BehaviorSubject<Datastore>(new Datastore());

  private data: Datastore = new Datastore();

  constructor(
    private authenticationService: AuthenticationService,
    private modalService: ModalService) {
    this.datastore.subscribe((data) => {
      this.data = data;
    });
  }

  public register() {
    return this.datastore.asObservable();
  }

  public initializeDatastore(datastore: Datastore) {

    datastore.role = this.authenticationService.getUserRole();

    //// Display a reminder to register after this level.
    //if (datastore.role === Constants.RoleGuest) {
    //  var nextRegistrationTeaser = parseInt(localStorage.getItem(Constants.RegistrationTeaserLevelKey), 10);
    //  if (isNaN(nextRegistrationTeaser)) {
    //    nextRegistrationTeaser = Constants.InitialGuestRegistrationTeaserLevel;
    //    localStorage.setItem(Constants.RegistrationTeaserLevelKey, "" + nextRegistrationTeaser + "");
    //  }
    //  datastore.guestRegistrationTeaserLevel = nextRegistrationTeaser;
    //}

    this.data = datastore;
    this.datastore.next(this.data);
  }

  // Request a refresh of the member list, such as after a member request is confirmed. 
  public memberListIsStale() {
    this.data.isMemberListStale = true;
    this.datastore.next(this.data);
    this.data.isMemberListStale = false;
  }

  public setMemberCode(code: string) {
    this.data.memberCode = code;
    this.datastore.next(this.data);
  }

      //// Check if we need to show the registration teaser modal
      //if (this.data.role === Constants.RoleGuest && this.data.level >= this.data.guestRegistrationTeaserLevel) {
      //  this.modalService.showRegistrationTeaserModal(this.data);
      //  this.updateRegistrationTeaserLevel();
      //}

  //public updateRegistrationTeaserLevel() {
  //  if (this.data.role === Constants.RoleGuest) {
  //    var nextLevel = parseInt(localStorage.getItem(Constants.RegistrationTeaserLevelKey), 10) + Constants.InitialGuestRegistrationTeaserLevel;
  //    localStorage.setItem(Constants.RegistrationTeaserLevelKey, "" + nextLevel + "");
  //    this.data.guestRegistrationTeaserLevel = nextLevel;
  //    this.datastore.next(this.data);
  //  }
  //}

}
