<app-header></app-header>

<div class="callout">
  <form>
    <h2><a onclick="history.back()"><img src="../../assets/images/Icons/Back.png" width="20" height="20" /> &nbsp;To: {{recipient?.name}}</a></h2>
    <textarea autofocus name="Message" id="Message" [(ngModel)]="message" maxlength="255" class="form-control" #box (keyup.enter)="sendChat();"></textarea><br />
    <div class="small-text">All chat messages must comply with our <a [routerLink]="'/terms'">Terms of Service</a>. Sending a chat message acknowledges agreement.</div><br />
    <p>
      <button class="form-button" (click)="sendChat()" [disabled]="isSending">Send</button>
      <ngb-alert *ngIf="resultText" (close)="resultText = null" type="danger">
        <div [innerHTML]="resultText"></div>
      </ngb-alert>
    </p>
  </form>
</div>

<div *ngFor="let chat of chatMessages" [ngClass]="chat?.isSender ? 'ml-5' : 'mr-5'">
  <div [style]="chat?.isSender ? 'border-radius: 8px 0px 0px 8px; padding: 10px 20px 10px 20px' : 'border-radius: 0px 8px 8px 0px; padding: 10px 20px 10px 20px'" [ngClass]="chat?.isSender ? 'callout d-flex justify-content-end bg-primary text-dark' : 'd-flex callout'">
    <div [ngClass]="chat?.isSender ? 'text-right' : '' ">
      <span [ngClass]="chat?.isSender ? 'black-text' : 'text-white'" [innerHTML]="chat?.message"></span><br />
      <h6 [ngClass]="chat?.isSender ? 'text-dark small' : 'text-muted small'">({{chat?.time}})</h6>
    </div>
  </div>
</div>
