<div class="container-fluid">
  <div class="row align-items-center">
    <div class="col-xs-5 col-sm-6 col-med-6 col-12" style="min-width: 325px;">
      <a routerLink="/home"><img src="../../assets/images/Logo.png" alt="Send It To Me logo" style="height: 60px;" /></a>
    </div>
    <div class="col-xs-7 col-sm-6 col-med-6 col-12">


        <div *ngIf="! isLoggedIn() && !loggingIn" class="text-right">
          <button type="submit" (click)="tutorial()" class="form-button align-middle" style="font-size: 18px;">
            <img src="assets/images/LogoTransparent.png" width="30" class="align-middle" />
            &nbsp;Sign&nbsp;Up...
          </button>
          <br /> &nbsp; <br />
          <span class="small-text">Already have an account?</span><br /><button type="submit" (click)="loggingIn = true" class="form-button" value="Login" style="font-size: 14px;">Login</button>

        </div>
        <div *ngIf="! isLoggedIn()">

          <app-login *ngIf="loggingIn"></app-login>

          <div class="text-right">
            <br />
            <span *ngIf="loggingIn"> or </span>

            <input *ngIf="loggingIn" class="form-button" type="button" (click)="cancel()" value="Cancel">
          </div>

        </div>


    </div>
  </div>
</div>
<br />
