import { Component, OnInit } from '@angular/core';
import { PlayerService } from '../_services/player.service';
import { Dashboard } from '../_models/dashboard';
import { Constants } from '../_shared/constants';
import { DatastoreService } from '../_services/datastore.service';
import { Datastore } from '../_models/datastore';

@Component({
  selector: 'app-dashboard',
  templateUrl: './dashboard.component.html',
  styleUrls: ['./dashboard.component.css']
})
export class DashboardComponent implements OnInit {

  dashboard: Dashboard;
  data: Datastore;

  constructor(
    private playerService: PlayerService,
    private datastore: DatastoreService
  ) {
    this.datastore.register().subscribe((data) => {
      this.data = data;
    });
  }

  ngOnInit() {
    this.playerService.getDashboard()
      .subscribe(result => {
        this.dashboard = result.value;

        // Initialize datastore
        var initialStore: Datastore = new Datastore();
        initialStore.memberCode = result.value.groupName;
        this.datastore.initializeDatastore(initialStore);
      });
  }

}


