<app-header></app-header>

<div class="callout">

  <h2><a onclick="history.back()"><img src="../../assets/images/Icons/Back.png" width="20" height="20" /> &nbsp;{{summary?.name}}</a></h2>

  <p>
    Member Id: {{summary?.groupName}} &nbsp; <a class="mr-2" ngbTooltip="To add someone to your group, get their Member Id, or provide them with this value: {{summary?.groupName}}" triggers="click:blur">
      <img src="../../assets/images/Icons/Help.png" style="height: 13px;" />
    </a>
    <br />

    Group Size: {{summary?.groupSize}}
    <br />
    <ng-containter *ngIf="isGuest">Account Type: <span style="font-weight: bolder;">Guest</span></ng-containter>
  </p>

  <p>
    <button (click)="action()" class="form-button" type="button">
      {{actionString}}&nbsp;
    </button> &nbsp;
    <span *ngIf="isGuest">
      <a class="mr-2" ngbTooltip="Creating an account is free. It simply lets you log in from multiple devices and recover your password." triggers="click:blur">
        <img src="../../assets/images/Icons/Help.png" style="height: 14px;" />
      </a>
    </span>
  </p>

  <p>&nbsp;</p>

  <p><a href="https://testflight.apple.com/join/m9MRZi6z" target="_blank"><img src="../../assets/images/AppleStore-Beta.png" alt="Available on the Apple Store" height="45" /></a></p>

</div>
