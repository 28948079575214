import { Component, NgZone, OnInit, OnDestroy } from '@angular/core';
import { Router } from '@angular/router';
import { DatastoreService } from './_services/datastore.service';
import { Datastore } from './_models/datastore';
import { PlayerService } from './_services/player.service';
//import { Platform } from '@angular/cdk/platform';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})
export class AppComponent implements OnInit, OnDestroy  {
  title = 'app';

  data: Datastore;
  memberCode: string = '';

  constructor(
    private datastore: DatastoreService,
    private playerService: PlayerService,
    private router: Router,
    private ngZone: NgZone
  ) {
    this.datastore.register().subscribe((data) => {
      this.data = data;
    });
  }

  onActivate(component) {
    // If user is following a link containing a new member invite, store the new member id
    // eg: https://www.senditto.me/invite/ABC123
    if (this.router.url.includes('invite')) {
      var memberId = this.router.url.slice(this.router.url.lastIndexOf("/")+1);
      localStorage.setItem("newMemberId", memberId);
    }
  }

  ngOnInit() {
    // Called from store app to get user name
    // https://stackoverflow.com/a/39280942/2440994
    (<any>window).my = (<any>window).my || {};
    (<any>window).my.sitm = (<any>window).my.namespace || {};
    (<any>window).my.sitm.getMemberCode = this.getMemberCode.bind(this);
  }

  ngOnDestroy() {
    (<any>window).my.sitm.publicFunc = null;
  }

  getMemberCode(message: string) {
    return this.ngZone.run<string>(() => { return this.getMemberCodeInternal(message) });
  }

  getMemberCodeInternal(message: string) {
    let d = new Date();
    let date: string = d.getHours() + ':' + d.getMinutes() + ':' + d.getSeconds();

    // TODO: Convert to JSON
    this.memberCode = 'Date: ' + date + ' AppId: ' + message + ' Member: ' + this.data.memberCode;
    return this.memberCode;
  }

}
