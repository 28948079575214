import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, ParamMap } from '@angular/router';
import { switchMap } from 'rxjs/operators';
import { MessagingService } from '../_services/messaging.service';
import { ChatMessage } from '../_models/chatMessage';
import { PlayerService } from '../_services/player.service';
import { PlayerSummary } from '../_models/playerSummary';
import { Result } from '../_models/result';
import { Chat } from '../_models/chat';
import { Title } from '@angular/platform-browser';

@Component({
  selector: 'app-chat',
  templateUrl: './chat.component.html',
  styleUrls: ['./chat.component.css']
})
export class ChatComponent implements OnInit {

  constructor(
    private route: ActivatedRoute,
    private messagingService: MessagingService,
    private playerService: PlayerService,
    private title: Title) {
    title.setTitle("SendItTo.me - Chat");
  }

  groupName: string = '';
  chatMessages: ChatMessage[];
  recipient: PlayerSummary;
  player: PlayerSummary;
  message: string = '';
  result: Result<any>;
  resultText: string = '';
  refreshInterval: any;
  isSending: boolean = false;

  ngOnInit() {
    try {
      // Get the group name param from url
      this.route.paramMap.pipe(
        switchMap((params: ParamMap) =>
          params.get('id'))
      ).subscribe(val => this.groupName += val);
    } catch (e) { /*Should always be a group name passed in. */
    }

    this.getChatMessages();
    // And reload every n seconds
    this.refreshInterval = setInterval(() => {
      this.getChatMessages();
    }, 8000);

    this.playerService.getPlayerSummary(this.groupName)
      .subscribe(result => {
        this.recipient = result.value;
    });

    this.playerService.getPlayerSummary()
      .subscribe(result => {
        this.player = result.value;
      });
  }

  ngOnDestroy() {
    clearInterval(this.refreshInterval);
  }

  getChatMessages() {
    this.messagingService.getChatMessagesFromPlayer(this.groupName)
      .subscribe(result => {
        if (this.chatMessages && result.value.length > this.chatMessages.length) {
          if (Notification.permission === "granted") {

            var options = {
              body: 'You have received a new message.',
              icon: '/assets/favicon/icon-512x512.png',
              badge: '/assets/favicon/icon-128x128.png',
              silent: false,
              renotify: false,
              requireInteraction: false //,
              //tag: 'new-message'
            };

            var notification = new Notification("New Message", options);
          }
        }

        this.chatMessages = result.value;
      });
  }

  sendChat() {
    this.resultText = "";

    if (!this.message) {
      this.resultText = "Enter a Chat message.";
      return;
    }

    this.isSending = true;
    var chat: Chat = {
      "groupName": this.groupName,
      "message": this.message
    };

    this.messagingService.sendChatMessage(chat)
      .subscribe(result => {
        this.result = result;
        if (!result.success) {
          this.resultText = result.errorMessage;
          this.isSending = false;
          return;
        }
        var newMessage = [new ChatMessage(this.player.name, this.recipient.name, this.recipient.groupName, this.message, 'just now', true)];
        this.chatMessages = newMessage.concat(this.chatMessages);
        this.message = '';
        this.isSending = false;
      });
  }

}
