/// <reference path="_shared/authguard.guard.ts" />
/// <reference path="_services/player.service.ts" />
import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { RouterModule } from '@angular/router';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { AppComponent } from './app.component';

import { UserManagementComponent } from './user-management/user-management.component';
import { LoginComponent } from './login/login.component';
import { GuestComponent } from './guest/guest.component';
import { RegisterComponent } from './register/register.component';

import { MainSubMenuComponent } from './main-sub-menu/main-sub-menu.component';

import { HomeComponent } from './home/home.component';
import { AboutComponent } from './about/about.component';
import { TermsComponent } from './terms/terms.component';
import { PrivacyComponent } from './privacy/privacy.component';
import { FooterComponent } from './footer/footer.component';

import { PortComponent } from './port/port.component';
import { HeaderComponent } from './header/header.component';
import { SystemAlertsComponent } from './system-alerts/system-alerts.component';
import { GroupsComponent } from './groups/groups.component';
import { GroupRequestComponent } from './group-request/group-request.component';
import { GroupRequestsComponent } from './group-requests/group-requests.component';
import { PlayerComponent } from './player/player.component';
import { GroupMemberComponent } from './group-member/group-member.component';
import { ChatComponent } from './chat/chat.component';
import { ChatHistoryComponent } from './chat-history/chat-history.component';
import { DashboardComponent } from './dashboard/dashboard.component';

import { ContributeComponent } from './contribute/contribute.component';
import { DebugComponent } from './debug/debug.component';
import { StoreComponent } from './store/store.component';
import { HelpComponent } from './help/help.component';
import { ScreenshotCarouselComponent } from './screenshot-carousel/screenshot-carousel.component';
import { RegisterTeaserModalComponent } from './register-teaser-modal/register-teaser-modal.component';
import { TutorialModule } from './tutorial/tutorial.module';

import { MinutesSecondsPipe } from './_pipes/minutes-seconds.pipe';
import { RemoveSpacesPipe } from './_pipes/remove-spaces.pipe';
import { CommaSeparatedNumberPipe } from './_pipes/comma-separated-number.pipe';

import { AuthguardGuard } from './_shared/authguard.guard';
import { AuthInterceptor } from './_shared/auth-interceptor';

@NgModule({
  declarations: [
    AppComponent,

    // Common components
    FooterComponent,

    // Unauthorized components
    MainSubMenuComponent,
    HomeComponent,
      ScreenshotCarouselComponent,
    LoginComponent,
    StoreComponent,
    AboutComponent,
    TermsComponent,
    PrivacyComponent,
    ContributeComponent,
    DebugComponent,

    // Authorization components
    UserManagementComponent,
    GuestComponent,
    RegisterComponent,
    RegisterTeaserModalComponent,


    // Authenticated components
    HeaderComponent,
    DashboardComponent,
    PortComponent,
      SystemAlertsComponent,
      ChatHistoryComponent,
      GroupRequestComponent,
      GroupRequestsComponent,
      GroupsComponent,

    HelpComponent,
      ChatComponent,
    PlayerComponent,
    GroupMemberComponent,

    // Utilities
    CommaSeparatedNumberPipe,
    MinutesSecondsPipe,
    RemoveSpacesPipe,

  ],
  imports: [
    BrowserModule.withServerTransition({ appId: 'ng-cli-universal' }),
    FormsModule,
    HttpClientModule,
    NgbModule,

    TutorialModule,

    RouterModule.forRoot([
      { path: 'invite/:id', component: HomeComponent }, // User is coming from a URL with an invite member id
      { path: 'home', component: HomeComponent },
      { path: 'guest', component: GuestComponent },
      { path: 'login', component: LoginComponent },
      { path: 'about', component: AboutComponent },
      { path: 'contribute', component: ContributeComponent },
      { path: 'debug', component: DebugComponent },
      { path: 'terms', component: TermsComponent },
      { path: 'privacy', component: PrivacyComponent },
      { path: 'help', component: HelpComponent },
      //{ path: 'store', component: StoreComponent },

      // Secure
      { path: '', component: PortComponent, pathMatch: 'full', canActivate: [AuthguardGuard] },
      { path: 'register', component: RegisterComponent, canActivate: [AuthguardGuard] },
      { path: 'port', component: PortComponent, canActivate: [AuthguardGuard] },
      { path: 'member', component: PlayerComponent, canActivate: [AuthguardGuard] },
      { path: 'member/:id', component: GroupMemberComponent, canActivate: [AuthguardGuard] },
      { path: 'chat/:id', component: ChatComponent, canActivate: [AuthguardGuard] },

      // All other requests go to home page
      { path: '**', redirectTo: '', pathMatch: 'full' },
    ])
  ],
  providers: [
    { provide: HTTP_INTERCEPTORS, useClass: AuthInterceptor, multi: true }
  ],
  bootstrap: [AppComponent],
  entryComponents: [
    // Add all modal components to Entry Components here,,,
    RegisterTeaserModalComponent,
  ]
})
export class AppModule { }
