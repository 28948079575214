import { Component, getPlatform } from '@angular/core';
import { Router } from '@angular/router';
import { platformBrowser, Title } from '@angular/platform-browser';

@Component({
  selector: 'app-debug',
  templateUrl: './debug.component.html',
  styleUrls: ['./debug.component.css']
})
export class DebugComponent {

  constructor(
    private router: Router,
    private title: Title) {
    title.setTitle("SendItTo.me - Debug");
  }


  userAgent: string = navigator.userAgent;

  //value: string = platformBrowser;
}
